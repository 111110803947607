const data = {
    translations: {
        language: `Español`,
        home: {
            languages: {
                pt: `Português`,
                en: `English`,
                es: `Español`,
            },
            logo: `Observatorio Chagas`,
            about: `Acerca de`,
            aboutObservatory: `Acerca del Observatorio`,
            intro: {
                name: `Observatorio Chagas`,
                text1: `Instrumento de consulta pública, comunicación y incidencia con información sobre la <strong>Enfermedad de Chagas</strong>. Pretende responder a las preguntas: ¿Dónde y cómo estamos?, ¿cuántos somos? y ¿qué estamos haciendo? Basándose en estas antiguas preguntas pendientes planteadas por la sociedad civil, busca estimular diálogos e involucrar a diferentes actores en la construcción de políticas para combatir la enfermedad.`,
                text2: `Realizado por la <strong>Coalición Global de Chagas</strong>`,
                text3: `Apoyado por <strong>DNDi</strong>`,
                text4: `y la <strong>Federación Mundial del Corazón</strong>`,
                tooltipChagas: `La <a href="https://www.coalicionchagas.org/en/" target="_blank">Coalición Global de Chagas</a> es una alianza colaborativa para el intercambio de experiencias, conocimientos y capacidad de acción para aumentar la visibilidad de este problema y el compromiso de aliviar el sufrimiento humano que causa, con el objetivo de eliminarlo como un problema de salud pública.`,
                tooltipDndi: `La iniciativa <a href="https://dndial.org/" target="_blank">Iniciativa de Medicamentos para Enfermedades Olvidadas (DNDi)</a> es una organización internacional de investigación sin fines de lucro que desarrolla tratamientos y proyectos de acceso para las poblaciones más vulnerables, especialmente aquellas afectadas por enfermedades determinadas socialmente.`,
                tooltipWorld: `Liderando y organizando la <a href="https://world-heart-federation.org/what-is-cvd/" target="_blank">salud cardiovascular</a> global, la Federación Mundial del Corazón aboga por la salud cardíaca y trabaja para reducir la carga global de las enfermedades cardíacas, incluida la Enfermedad de Chagas. La Federación está compuesta por más de 200 fundaciones cardíacas, sociedades científicas y organizaciones de pacientes en más de 100 países.`,
            },
            phrase1: `¿Y si pudieras evitar que el corazón de alguien a quien amas dejara de latir de repente?`,
            phrase2: `Cuanto antes la persona descubra la enfermedad, mejor, ya que la fase aguda es <strong>curable en la mayoría de los casos</strong>.`,
            phrase3: `Estas son las dos formas de tratamiento: benznidazol y nifurtimox, y la distribución de medicamentos en todo el mundo.`,
            testimonial: {
                text: `Durante 10 años, me sometí a pruebas serológicas, y solo en 2020 (...) finalmente resultaron negativo para la enfermedad de Chagas. (...) Si yo pude, tú también puedes dar negativo si recibes atención oportuna, también puedes curarte.`,
                name: `Idalia Isabel Mercado Hernández`,
                label: `32 años. Asociación Amepach. México.`,
            },
            map: {
                text1: `Podemos contribuir a evitar que esto le suceda a aproximadamente <strong>7 millones de personas</strong> infectadas con la enfermedad de Chagas en todo el mundo.`,
                source1: `Fuente: OMS - Organización Mundial de la Salud.`,
                text2: `de esta población no sabe que tiene la enfermedad. En otras palabras, fortalecer la atención primaria para diagnosticar y tratar a tiempo es fundamental.`,
                source2: `Fuente: Organización Panamericana de la Salud.`,
            },
            charts: {
                pills: `<strong>5 millones</strong> de medicamentos distribuidos que equivaldrían a más de 21000 tratamientos proporcionados entre 2021 y 2022`,
                tooltipPills: `Considera tabletas de 100mg y 50mg para benznidazol Abarax producido por ELEA; tabletas de 100mg y 12.5mg para el benznidazol producido por LAFEPE; y tabletas de 100mg para Nifurtimox producido por Bayer. Actualmente, estos son los únicos medicamentos y productores para la enfermedad de Chagas en todo el mundo. Los datos de distribución de medicamentos corresponden a un total aproximado de 21,007 tratamientos realizados. Cálculo aproximado: 200 tabletas para 1 tratamiento con benznidazol.`,
                distribuition: `Regiones de distribución y tratamiento`,
                tooltipDistribuition: `Los datos se refieren a los años 2021 y 2022.`,
                tooltipCharts: `Datos de 2009 a 2022. Todas las tabletas corresponden a una dosis de 120mg (adulto).`,
                countries: `Distribución de <strong>Nifurtimox</strong> de 2009 a 2022`,
                source: `Fuente: ELEA, Exeltis, FMS España, FMS Argentina, OMS, Bayer y LAFEPE`,
                pie: {
                    data1: `América Latina`,
                    data2: `Europa`,
                    data3: `América del Norte`,
                    data4: `África, Asia y Oceanía`,
                },
                bars: {
                    2009: "270000",
                    2010: "467200",
                    2011: "464000",
                    2012: "466400",
                    2013: "268000",
                    2014: "1029200",
                    2015: "948600",
                    2016: "1212000",
                    2017: "1023500",
                    2018: "1063600",
                    2019: "1179200",
                    2020: "529600",
                    2021: "1529000",
                    2022: "951700",
                },
                bars_labels: {
                    2009: "270.000",
                    2010: "467.200",
                    2011: "464.000",
                    2012: "466.400",
                    2013: "268.000",
                    2014: "1.029.200",
                    2015: "948.600",
                    2016: "1.212.000",
                    2017: "1.023.500",
                    2018: "1.063.600",
                    2019: "1.179.200",
                    2020: "529.600",
                    2021: "1.529.000",
                    2022: "951.700",
                },
            },
            years: `A pesar de que la enfermedad de Chagas fue descubierta hace más de 100 años, todavía queda mucho por hacer para eliminarla como problema de salud pública.`,
            poorData: `En parte, porque tradicionalmente se asocia con poblaciones con menos recursos que viven en áreas remotas y rurales y, en parte, debido a la falta de concienciación y compromiso político y financiero.`,
            slideshow: {
                1: `Recife, Brasil. 2023. Xavier Vahed-DNDi`,
                2: `Recife, Brasil. 2023. Xavier Vahed-DNDi`,
                3: `Recife, Brasil. 2023. Xavier Vahed-DNDi`,
                4: `Jutiapa, Guatemala. 2023. Dan Ramírez Guerrero-DNDi`,
                5: `Jutiapa, Guatemala. 2023. Dan Ramírez Guerrero-DNDi`,
                6: `Jutiapa, Guatemala. 2023. Dan Ramírez Guerrero-DNDi`,
                7: `Santander, Colombia. 2019. Ana Ferreira-DNDi`,
                8: `Argentina. 2015. Bayer`,
                9: `Argentina. 2015. Bayer`,
                10: `Mendoza, Argentina. 2015. Bayer`,
                11: `Mendoza, Argentina. 2015. Bayer`,
                12: `Mendoza, Argentina. 2015. Bayer`,
            },
            slideshowAlt: {
                1: `mujer sentada en el barranco viendo a un niño saltar`,
                2: `mujer viendo las cartelas de los medicamentos sobre la mesa`,
                3: `mujer acostada al fondo y en primer plano electrodos`,
                4: `familia, madre de pie y dos niños sentados`,
                5: `consulta médica, hombre acostado`,
                6: `mujer de azul sentada en consulta médica`,
                7: `hombre en fila`,
                8: `En Argentina`,
                9: `En Argentina`,
                10: `En Argentina`,
                11: `En Argentina`,
                12: `En Argentina`,
            },
            flags: {
                title: `<strong>El Observatorio</strong> nace como resultado del esfuerzo de 7 países y socios, y viene a aumentar la visibilidad sobre la situación del Chagas en el mundo, así como hacer que la información existente sea fácilmente accesible para todas las partes interesadas. Es importante recordar que hay una inmensa <strong>subnotificación</strong> de casos de la enfermedad en todo el mundo, ya que menos del 10% de las personas con la infección por Chagas reciben diagnóstico y tratamiento oportunos.`,
                source: `Fuente: Organización Panamericana de la Salud.`,
                country1: `Argentina`,
                country2: `Brasil`,
                country3: `Colombia`,
                country4: `Guatemala`,
                country5: `Paraguay`,
                country6: `UK/Londres`,
                country7: `CH/Ginebra`,
            },
        },
        about: {
            intro: {
                name: `Sobre el <br /> Observatorio <br /> Chagas`,
                text: `<strong>Se escuchan algunos pasos</strong><br /><br />
        Avanzan. Algunos países avanzan con pasos cortos y lentos, pero están avanzando. Avanzan en medio del silencio epidemiológico que hace de la enfermedad de Chagas una de las enfermedades más invisibles.<br /><br />
        Hasta ahora, 115 años después de su descubrimiento, el progreso más notable ha sido en el control de la transmisión vectorial de esta enfermedad, que es endémica en muchas regiones de las Américas. Pero este progreso no ha ocur.rido en la misma medida para proporcionar atención a las personas afectadas por la enfermedad.<br /><br />
        Hace dos años, las personas que viven con la enfermedad de Chagas hicieron un llamado en el Día Mundial de la Enfermedad de Chagas (14 de abril): "Ayúdenos a saber cuántos somos y dónde estamos". Porque, ahora, los datos que tenemos sobre la enfermedad de Chagas son solo estimaciones. Tal vez haya más de 75 millones en riesgo. Tal vez haya más de 7 millones actualmente infectados. Seguramente son muchos.<br /><br />
        Sin información más clara y confiable, los sistemas de salud enfrentan decisiones difíciles cuando se trata de asignar recursos adecuadamente. Y la sociedad también desconoce contra qué está luchando, tanto en las regiones endémicas de las Américas como en el resto del mundo, donde la enfermedad se ha propagado a través de movimientos migratorios y otras rutas de transmisión no vectoriales, como la transmisión de madre a hijo.<br /><br />
        Sin embargo, hoy tenemos más evidencia y experiencia en el manejo de la enfermedad que en el pasado. Por lo tanto, conocemos la inmensa importancia de la atención integral. Esa es la apelación del Día Mundial de Chagas de este año en 2024: diagnóstico y tratamiento oportunos y atención continua para las personas que viven con Chagas. Prevenir la transmisión y evitar el daño de la infección y la muerte. Es una carrera en medio de la neblina, con falta de información precisa. Pero algunos datos muestran que se están logrando avances.<br /><br />
        Este observatorio trata sobre los avances realizados por los países en la lucha contra la enfermedad de Chagas y se basa en datos proporcionados por un grupo de países y sus sistemas de salud, así como por algunos socios de la Coalición Global de Chagas. La información disponible se centra en ampliar la atención de salud.<br /><br />
        Esta primera edición destaca los avances realizados en Brasil, Argentina, Colombia, Guatemala y Paraguay, junto con el cantón de Ginebra en Suiza y la ciudad de Londres en el Reino Unido. La diversidad geográfica de esta primera muestra nos da una idea general de la situación global.<br /><br />
        Todos los avances identificados indican que, poco a poco, se está rompiendo el silencio epidemiológico. Por ejemplo, la implementación de un sistema de notificación obligatoria para casos crónicos en Brasil, el aumento de la población diagnosticada en áreas de alta incidencia en Colombia, las políticas avanzadas implementadas en Argentina, son solo algunos de los signos de progreso hacia el objetivo final de eliminar el Chagas como un problema de salud pública. Al mismo tiempo, los datos sobre la distribución de los dos medicamentos existentes para la enfermedad en todo el mundo hoy (Benznidazol y Nifurtimox) nos acercan al número real de personas que los países han tratado.<br /><br />
        La creación del Observatorio Chagas fue en respuesta a la solicitud de las personas afectadas por la enfermedad de Chagas de "saber cuántos somos y dónde estamos" y la necesidad de establecer diálogos entre los sistemas de salud y la sociedad civil. Este año, se espera que las estimaciones globales de la Organización Panamericana de la Salud (OPS) se actualicen, así como otras iniciativas que buscan desvelar la realidad oculta de los datos de la enfermedad de Chagas.<br /><br />
        No es fácil encontrar información básica sobre el cuidado de las personas afectadas en los países. A menudo, son invisibles para los sistemas de vigilancia de los ministerios de salud mismos porque la enfermedad no se prioriza o no recibe los recursos adecuados. La propia complejidad de la enfermedad, que es asintomática en muchos casos, tampoco facilita. Pero es necesario insistir en que haya información más precisa y accesible, que permita la planificación de intervenciones en salud y la dedicación de los recursos necesarios. También son la base para investigaciones exitosas sobre nuevas herramientas de diagnóstico y tratamiento.<br /><br />
        Nuestro Observatorio es un pequeño primer paso, con un grupo piloto de países loables con los que hemos estado en diálogo para crear este proyecto. Su objetivo es simplemente dar una visión general y una fotografía de lo que se sabe sobre la enfermedad de Chagas hoy en estos lugares y fomentar que otros recopilen y compartan su información y datos sobre esta causa que nos pertenece a todos.<br /><br />
        Todavía estamos lejos de ver la magnitud real de la enfermedad en todas sus dimensiones, pero este progreso llega hasta nosotros con el sonido de algunos pasos, en medio de la oscuridad que aún rodea esta enfermedad.<br /><br />
        <i>Firmado por: Secretaría de la Coalición Global de Chagas (CEADES, DNDi, Fundación Mundo Sano, ISGlobal, Centro de Desarrollo de Vacunas del Texas Children's Hospital - Y Baylor College of Medicine)<br /><br />
        El Observatorio de Progreso de Chagas es una iniciativa del grupo de trabajo de defensa de la Coalición Global de Chagas, dirigido por miembros voluntarios de organizaciones como DNDi y la Federación Mundial del Corazón.</i>`,
            },
            content: {
                name: `La necesidad de información adecuada`,
                text: `<i>Por Roberto Chuit, consultor de la Organización Panamericana de la Salud.</i><br /><br />
        La falta de registros y/o datos adecuados para la enfermedad de Chagas hace necesario realizar estimaciones de prevalencia, lo que presenta dificultades en su ejecución, ya que muchas veces se basan en estudios de áreas específicas, con diseños heterogéneos y tamaños de muestras variados, que implican diferentes escalas temporales; a veces, estos estudios no son representativos de una región geográfica, al no considerar la diversidad y los cambios ambientales.<br /><br />
        El propósito de las estimaciones es ayudar en la toma de decisiones, la planificación de acciones, la elaboración de presupuestos y la gestión de recursos con distribución de manera apropiada. Debe tenerse en cuenta que una estimación es un elemento estadístico que abarca una amplia gama de posibilidades, y su precisión deriva de las fuentes de datos en que se basa, así como de las diferentes valoraciones o definiciones en las variables que la componen, pudiendo, por lo tanto, modificar el valor final estimado.<br /><br />
        Como se observa, para realizar estimaciones precisas, dependemos de las fuentes de datos que, en el caso de la enfermedad de Chagas, presentan imprecisiones en sus definiciones en los registros, pudiéndose mencionar algunas:<br /><br />
        - Registro de casos agudos por causa vectorial: aunque en los países, en general, existe la obligatoriedad de notificación, el diagnóstico clínico presenta dificultades, ya que la fase aguda, por picadura del insecto, muchas veces pasa desapercibida debido a síntomas leves y/o comunes a otras enfermedades. La falta de disponibilidad de diagnóstico parasitológico directo a nivel local (Strout o micro-Strout) y/o la falta de consideración por parte de los profesionales de la salud como una posible causa resulta en su ausencia como entidad nosológica.<br /><br />
        - Registro de casos de transmisión perinatal: la dificultad en este caso radica en no diagnosticar a la gestante positiva y en la ausencia de estudio de su recién nacido. Si la gestante es conocida como positiva, la falta de disponibilidad de diagnósticos parasitológicos en la maternidad para el estudio del recién nacido o su seguimiento inmunológico según las normas resulta en la falta de estudio y por ende en el subregistro de esta causa.<br /><br />
        - Registro de personas con afección crónica debido a la infección: recién recientemente los registros están siendo ajustados en los países, motivo por el cual aún se notifican casos positivos sin diferenciar si son crónicos o agudos.<br /><br />
        - Registros de mortalidad: al analizar estadísticas vitales en los países, en general, las causas cardíacas, digestivas y otras no figuran en la proporción que, de acuerdo con los datos de los estudios específicos realizados en diferentes regiones, deberían existir.<br /><br />
        - Registros atomizados y no coordinados: en general, los casos vectoriales son registrados por los programas de control vectorial, los casos agudos en el sistema de vigilancia de la salud, los congénitos en el sistema de atención médica y los de pacientes crónicos en las estadísticas hospitalarias y no siempre son unificados en una sola base de datos.<br /><br />
        La dificultad de los registros y la organización de los sistemas de salud ocultan la verdadera presencia de la enfermedad y confunden las notificaciones de casos con el perfil epidemiológico de la infección en una región, es decir, la verdadera presencia de la enfermedad, ya que, como se mencionó anteriormente, la notificación solo informa una fracción de lo que ocurre en un área.<br /><br />
        Ante lo expuesto, para que las estimaciones sean precisas, es necesario desarrollar estudios de prevalencia para cada país y región. En la imposibilidad de realizar un estudio de tamaño abarcador, y en caso de optar por realizarlo, debe reflejar la realidad y diversidad geográfica (cambios ambientales, económicos, acciones de control, entre otros), cambio en el riesgo de transmisión debido a la variación en la presencia del vector principal o sustitución del mismo por vectores secundarios, subnotificación de casos (agudos vectoriales, transmisión congénita, oral, etc.), subregistro de casos crónicos.<br /><br />
        En resumen, podemos afirmar que, para obtener mejores informaciones y, con el objetivo de saber dónde estamos, cómo estamos y cuántos infectados existen, es necesario mejorar los registros, la disponibilidad y la identificación adecuada de estos, siendo también imperativo que los programas gubernamentales de salud pública sean apropiados y ajustados a cada una de las realidades locales.
        `,
            },
        },
        countries: {
            argentina: {
                name: `Argentina`,
                description: `Se estima que alrededor de un millón y medio de personas tienen la enfermedad de Chagas en el país, lo que la convierte en uno de los principales problemas de salud pública. Frente a este escenario, Argentina ha implementado intervenciones continuas y programas de concientización para mitigar el impacto de la enfermedad, igualmente ha buscado mejorar la calidad de la atención para pacientes con Chagas.`,
                questions: {
                    moreInfo: `Proyecto InChagas: impacto de una intervención educativa para promover la detección y atención oportuna de la miocardiopatía chagásica.<br /><br />

          El Instituto de Efectividad Clínica y Sanitaria (IECS) está afiliado a la Facultad de Medicina de la Universidad de Buenos Aires y coordina el proyecto InChagas. El objetivo principal de este proyecto es mejorar la difusión de recomendaciones basadas en la evidencia para mejorar la detección y la calidad de la atención de las personas afectadas con miocardiopatía chagásica en una provincia endémica de Argentina.<br /><br />
          
          El proyecto, que se encuentra en su fase inicial de implementación, desarrollará e implementará estrategias educativas basadas en herramientas de tele-educación, dirigidas a médicos de atención primaria. Al mejorar la formación de los médicos del primer nível de atención, InChagas pretende mejorar el conocimiento de esta enfermedad en este nível, lo que redundará en una mejor detección e interacción entre los distintos niveles de atención. La estrategia del proyecto se basa en videos educativos cortos, seminarios web, módulos interactivos y la difusión de materiales educativos digitales. Todo el desarrollo de contenidos y su adaptación a la realidad local se llevará a cabo en estrecha colaboración con expertos locales en la enfermedad de Chagas y otras contrapartes provinciales interesadas. Las actividades sobre el terreno comienzan en el primer semestre de 2024.<br /><br />
          
          InChagas tiene el objetivo de contribuir a fortalecer las intervenciones y estrategias locales para mejorar la atención integral y el manejo clínico de las personas con miocardiopatía chagásica. <br /><br />
          Este estudio cuenta con el apoyo del Proyecto Líderes Emergentes de la Federación Mundial del Corazón
          `,
                    source: `<strong>Fuente de Población</strong>: CENSO 2022.<br />
        <strong>Fuente de Datos</strong>: Sistema Nacional de Vigilancia (SNVS 2.0).<br />
        Estos datos se obtuvieron a partir de los valores totales por categoría, y para calcular la proporción por sexo se utilizaron proyecciones de población del INDEC (Instituto Nacional de Estadística y Censos de la República Argentina).`,
                },
            },
            brasil: {
                name: `Brasil`,
                description: `Brasil ha desempeñado un papel destacado en la lucha regional contra la enfermedad de Chagas, una enfermedad descubierta por el brasileño Carlos Chagas en 1909. Actualmente, el país está en proceso de implementación de la notificación de casos crónicos. También es parte del proyecto <a href="https://cuidachagas.org/" target="_blank">CUIDACHAGAS</a> y recientemente ha lanzado el <a href="https://www.gov.br/saude/pt-br/assuntos/brasil-saudavel" target="_blank">Programa Brasil Saludable</a>, una iniciativa ambiciosa y muy bienvenida en la que Chagas también se destaca. <br /><br />Los datos cuantitativos para 2023 no se han recopilado, por lo tanto, son datos preliminares y pueden no reflejar la realidad del país en cuanto al escenario epidemiológico este año.<br />Puede haber duplicidad de datos para mujeres embarazadas.`,
                questions: {
                    moreInfo: `Estudio RAISE: la carga de la enfermedad de Chagas<br /><br />

          El profesor Tom Ribeiro es cardiólogo en el Hospital Universitario de la Universidad Federal de Minas Gerais (UFMG) y atiende pacientes de Chagas.<br /><br />
          
          Según el profesor, cuando una persona afectada presenta signos de insuficiencia cardíaca, la enfermedad de Chagas siempre aparece en la lista de causas subyacentes. "Además, la falta de diagnóstico también se debe a que las personas afectadas con Chagas y sus familias suelen recibir una atención sanitaria de menor calidad", y añade que "a la hora de rellenar los certificados de defunción de las personas que fallecen por una enfermedad cardíaca, el Chagas también se olvida". Los certificados de defunción son una fuente muy importante de datos e información, y con demasiada frecuencia la enfermedad de Chagas se clasifica erróneamente como "otras cardiopatías".<br /><br />
          
          Para comprender mejor estas cuestiones, Ribeiro y el equipo de la Universidad Federal de Minas Gerais - Brasil, pusieron en marcha el proyecto RAISE ("El peso de la enfermedad de Chagas en el mundo contemporáneo"), que cuenta con el apoyo de la Federación Mundial del Corazón, Novartis Global Health y el Instituto de Salud de la Universidad de Washington. El proyecto también tiene entre sus objetivos calcular la carga de la enfermedad y de la insuficiencia cardiaca congestiva, con el fin de estimar los costes asociados a esta afección y a otras complicaciones específicas. Esperamos que los resultados de este estudio contribuyan a romper el silencio epidemiológico de la enfermedad.<br /><br />
          
          Participación social: el protagonismo de los decisores brasileños<br /><br />
          
          El Sistema Único de Salud (SUS) es el resultado de una larga movilización social en favor de mejores condiciones de salud pública en Brasil. A pesar de los evidentes avances logrados en términos de acceso universal, siguen existiendo importantes desafíos, especialmente en lo que se refiere a enfermedades socialmente determinadas, como el Chagas. En este contexto, el surgimiento de iniciativas como el Foro Social Brasileño para el Abordaje de las Enfermedades Infecciosas y Desatendidas (FSBEIN) es extremadamente importante. Este foro no sólo amplía el debate sobre estas enfermedades, sino que también promueve la articulación entre diferentes actores sociales, gubernamentales y científicos, buscando soluciones eficaces e inclusivas.<br /><br />
          
          De hecho, fruto del FSBEIN es el nacimiento de una asociación de personas afectadas por enfermedades socialmente determinadas, el Movimiento Nacional de Enfermedades Desatendidas (MNDN). Según João Victor Pacheco, uno de los miembros fundadores, "el MNDN es una respuesta enérgica a las injusticias a las que se enfrentan las comunidades afectadas por enfermedades como la lepra, la tuberculosis, la filariasis, la leishmaniasis, la esquistosomiasis, el Chagas y otras. Es más que un movimiento, es una promesa de esperanza y transformación. Una llamada a la acción para todos aquellos que comparten nuestra visión de un mundo en el que la salud y la dignidad sean accesibles para todos, independientemente de su condición social o económica."
          `,
                    source: `<strong>Fuente de la población</strong>: IBGE - Censo 2022.<br />
          <strong>Fuente de la prevalencia de Chagas</strong>: II Consenso Brasileño sobre la Enfermedad de Chagas, 2015.<br />
          <strong>Fuente de datos</strong>:<br />
          - Dirección Ambiental de Laboratorios.<br />
          - Sistema de Información de Enfermedades de Declaración Obligatoria - Sinan.
          `,
                },
            },
            colombia: {
                name: `Colombia`,
                description: `Colombia tiene un liderazgo sólido en América Latina para el control y la eliminación de la enfermedad de Chagas como problema de salud pública. Existe un fuerte compromiso para llegar a aquellas áreas que no han sido accesibles en los últimos años debido al conflicto armado.`,
                questions: {
                    moreInfo: `Rutas Integrales de Atención en Salud<br /><br />
          Las Rutas Integrales de Atención en Salud (RIAS), o líneas de atención, son protocolos o guías establecidas para proporcionar una ruta clara e integral para el diagnóstico, tratamiento y seguimiento de una determinada condición de salud.<br /><br />
          La experiencia piloto en Colombia, liderada por el Ministerio de Salud y Protección Social, el Instituto Nacional de Salud y varios departamentos endémicos del país, y apoyada por DNDi, ha demostrado que a través de la implementación de las RIAS es posible simplificar el proceso de diagnóstico y reducir el tiempo de espera para el inicio del tratamiento, fortaleciendo los sistemas locales y contribuyendo al control de la enfermedad de Chagas. La implantación de las RIAS aumentó el número de personas con acceso al diagnóstico y redujo el tiempo medio hasta el diagnóstico definitivo de 258 a 19 días y el tiempo entre el diagnóstico y el inicio del tratamiento de 354 a 127 días, lo que supone una reducción del 64% en los tres años de duración de los proyectos piloto.<br /><br />
          Un análisis económico realizado por el proyecto piloto determinó que las RIAS aumentan el acceso a la asistencia sanitaria, ya que reducen la necesidad de que las personas que reciben tratamiento para la enfermedad acudan a citas médicas en hospitales de referencia especializados, que pueden estar lejos de las comunidades en las que viven. En estos casos en los que la atención es centralizada, se produce un aumento significativo de los gastos del paciente (una media de 68.453 pesos colombianos -18,00 dólares-, y/o más de dos días de pérdida de ingresos). Estos costes adicionales, no médicos, corresponden a gastos como alojamiento, comida, transporte y otros servicios, que normalmente no se producen cuando el paciente recibe atención en el primer nível de salud.<br /><br />
          Las RIAS a nivel nacional y la publicación de guías clínicas<br /><br />
          Sin embargo, las RIAS para Chagas en Colombia aún no se han implementado a nivel nacional, mientras esperamos la publicación de la Guía Clínica para la Atención Integral del Chagas, que orientará a los actores del sistema de salud en el proceso de atender la enfermedad. Esperamos muy pronto poder celebrar ambos avances, que fortalecerán la atención primaria y garantizarán el acceso descentralizado para el diagnóstico y tratamiento oportuno.          
          `,
                    source: `<strong>Fuente población</strong>: Departamento Administrativo Nacional de Estadística - DANE, publicado en marzo de 2023.<br />
          <strong>Fuente prevalencia de Chagas</strong>: Olivera MJ, Fory JA, Porras JF, Buitrago G (2019) Prevalence of Chagas disease in Colombia: A systematic review and meta-analysis. PLoS ONE 14(1): e0210156<br />
          <strong>Fuente población en riesgo de Chagas</strong>: Ministerio de Salud y Protección Social, Grupo de gestión integrada de enfermedades endemo-epidémicas.<br />
          <strong>Fuente de datos</strong>:<br />
          - Tamizajes colectivos programas ETV, Colombia; Programas ETV, estrategia ETMI PLUS, consolidado por Ministerio de Salud y Protección Social Colombia; Red nacional de bancos de sangre, Instituto Nacional de Salud- INS.<br />
          - Programas departamentales de ETV, consolidados por el Ministerio de Salud y Protección Social Colombia.
          
        `,
                },
            },
            guatemala: {
                name: `Guatemala`,
                description: `La enfermedad de Chagas es un problema prioritario de salud pública en Guatemala, siendo el país de América Central con mayor carga de la enfermedad y casos agudos. En los últimos años, el país ha avanzado significativamente en términos de descentralización del diagnóstico, y se están realizando esfuerzos para fortalecer esta capacidad, así como el tratamiento, en las Áreas de Salud en las regiones endémicas del país.`,
                questions: {
                    moreInfo: `Generando evidencias para el algoritmo diagnóstico<br /><br />

          El Proyecto Alianzas fue una iniciativa interinstitucional lanzada en 2018 con el objetivo de contribuir a la eliminación de la enfermedad de Chagas como problema de salud pública en Guatemala. Específicamente, en relación con el diagnóstico, el proyecto ayudó a sentar las bases para fortalecer el Laboratorio Nacional de Salud (LNS) y descentralizar el diagnóstico de la enfermedad de Chagas. Desde entonces, han surgido otras iniciativas con el mismo propósito, siempre lideradas por autoridades locales.<br /><br />
          
          Con el fin de contribuir a la evidencia sobre la calidad de los procesos diagnósticos y complementar el algoritmo diagnóstico actual en el país, el LNS realizó en 2021 un estudio para evaluar 07 pruebas serológicas disponibles en Guatemala, no cromatográficas, para la detección de anticuerpos IgG anti-T.cruzi. En general, tres de las siete metodologías tamizadas mostraron una sensibilidad aceptable y todas tuvieron la capacidad de clasificar a un individuo sano como negativo (especificidad). En un esfuerzo por seguir mejorando la capacidad de diagnóstico del país, en 2023 se llevó a cabo otro estudio para comparar el rendimiento de 3 pruebas rápidas en campo, sin embargo ninguna de ellas alcanzó una sensibilidad suficiente. Se están planificando o están en curso nuevos estudios para seguir analizando el rendimiento de las pruebas rápidas.<br /><br />
          
          Atención descentralizada y ETMI-PLUS<br /><br />
          
          Con la apertura del laboratorio descentralizado para el diagnóstico de la enfermedad de Chagas en el departamento de El Quiché en diciembre de 2023, ya son 4 los laboratorios de este tipo en el interior de Guatemala para reforzar el diagnóstico a nivel nacional. La descentralización del diagnóstico es muy beneficiosa para el control de la enfermedad, ya que amplía el acceso al diagnóstico oportuno, trae consigo la capacitación del equipo de salud, actividades de búsqueda y sensibilización de la comunidad y favorece la atención médica en atención primaria.<br /><br />
          
          Además de reforzar la atención primaria, para 2024 se espera mejorar la base de datos del país con información sobre el sexo y la edad de las personas que figuran en el indicador de los tratamentos administrados. También hay planes para aumentar las pruebas y el tratamiento para mujeres en edad fértil y embarazadas, situando definitivamente el Chagas dentro de la iniciativa ETMI-plus (Eliminación de la Transmisión maternoinfantil).
          `,
                    source: `<strong>Fuente de Población</strong>: <a href="https://datatopics.worldbank.org/world-development-indicators/" target="_blank">https://datatopics.worldbank.org/world-development-indicators/</a><br />
        <strong>Fuente de Datos</strong>: <br />
        - Bases de datos de la Dirección del Laboratorio Nacional de Salud y del Sistema Gerencial de Salud SIGSA.<br />
        - Base de datos del Programa de Enfermedades Transmitidas por Vectores del Ministerio de Salud Pública y Asistencia Social.<br />
        - Bases de datos de hospitales nacionales y áreas de salud a nivel nacional.
        `,
                },
            },
            paraguai: {
                name: `Paraguay`,
                description: `El país ha avanzado significativamente en el control de la enfermedad de Chagas, habiendo aumentado el número de personas tratadas en los últimos 3 años así como la capacidad diagnóstica e integrado pruebas rápidas en el algoritmo. Así mismo el personal médico ha recibido mejor formación. Todo ello con el apoyo de organizaciones y proyectos de cooperación.  Actualmente se enfatiza el diagnóstico y tratamiento de mujeres en edad fértil y, especialmente, embarazadas.`,
                questions: {
                    moreInfo: `Logros reales y desafíos en la eliminación de las formas de transmisión<br /><br />

          En las últimas décadas, Paraguay ha logrado muchos avances en el control vectorial. En la década de los setenta se llegó a detectar hasta 80% de infestación doméstica en el Chaco paraguayo. Fue a través de su participación en la Iniciativa del Cono Sur de América para la eliminación del Triatoma infestans y el Plan de Control Vectorial de la Enfermedad de Chagas en la década de los 90 que el país logró la Certificación Internacional de la Interrupción de la Transmisión Vectorial en 2018. Actualmente, el país no presenta formas de transmisión vectorial ni oral ni por donación de sangre (en bancos de sangre se detecta una prevalencia de donantes positivos de 1,5-2%). La vía congénita es la que sigue presente y supone el centro atención.<br /><br />
          
          A pesar de que Paraguay comenzó a tomar medidas para controlar la transmisión vertical en 1995, aún existen muchos desafíos en la actualidad, ya que existe una brecha significativa en la cobertura de pruebas en mujeres embarazadas, que alcanza a menos del 20%. Teniendo en cuenta la prevalencia esperada (5% en embarazadas - unas 7.500 mujeres) y la tasa de transmisión vertical, se estima que cada año nacen unos 400 bebés con la infección, de los cuales sólo se detecta al 8%.<br /><br />
          
          [<a href="https://senepa.gov.py/2021/07/09/paraguay-refuerza-la-lucha-contra-la-enfermedad-de-chagas-con-las-primeras-guias-de-manejo-de-la-patologia/#:~:text=En%20Paraguay%2C%20actualmente%2C%20la%20principal,al%20a%C3%B1o%20con%20la%20infecci%C3%B3n" target="_blank">referencia</a>]<br /><br />

          La capacidad diagnóstica y de manejo clínico fue reforzada en los últimos años. Así mismo cabe destacar las capacitaciones técnicas al personal de salud. Todo ello gracias a la colaboración de organizaciones de salud como ISGlobal, fundación PROBITAS y la agencia de cooperación española, AECID.<br /><br />
          
          Con la entrada del país a la iniciativa ETMI Plus, Paraguay refuerza su compromiso de aumentar las pruebas serológicas rutinarias a las mujeres embarazadas, así como las pruebas de detección de las enfermedades incluidas en esta iniciativa. En la misma línea, el país forma parte de la iniciativa iberoamericana Ningún Bebé con Chagas, (que es un compromiso de Estados iberoamericanos) así como del Proyecto CUIDA Chagas (que incluye el uso de pruebas rápidas), cuyo principal objetivo es contribuir a eliminar la transmisión vertical de la enfermedad.`,
                    source: `<strong>Fuente de Población</strong>: <a href="https://datatopics.worldbank.org/world-development-indicators/" target="_blank">https://datatopics.worldbank.org/world-development-indicators/</a> <br />
        <strong>Fuente de Datos</strong>: Programa Nacional de Chagas - Ministerio de Salud de Paraguay.`,
                },
            },
            reinounido: {
                name: `Reino Unido/Londres`,
                description: `De acuerdo con el Censo de 2021, el Reino Unido alberga casi 300,000 personas migrantes nacidos en países endémicos de Chagas, con más de la mitad residiendo en Londres. Los donantes de sangre procedentes de regiones endémicas han sido tamizados para T. cruzi desde la década de 1980; sin embargo, se estima que la gran mayoría de las personas afectadas por Chagas en el Reino Unido permanecen sin diagnóstico.`,
                questions: {
                    moreInfo: `El Chagas Hub del Reino Unido<br /><br />

          Aunque las personas con enfermedad de Chagas han sido atendidas en el Hospital de Enfermedades Tropicales (HTD) de Londres durante muchos años, fue en 2017 cuando comenzaron los esfuerzos coordinados para abordar adecuadamente esta enfermedad desatendida, mediante la aparición de un grupo multidisciplinar denominado UK Chagas Hub. El Hub (Centro) es una colaboración que reúne a profesionales de la salud, investigadores, defensores y miembros de la comunidad latinoamericana para abordar la enfermedad de Chagas en el Reino Unido y contribuir a los esfuerzos mundiales para eliminarla. El HTD ofrece servicios de diagnóstico y tratamiento en una clínica especializada en la enfermedad de Chagas, la única de este tipo en el Reino Unido, y la gestiona personal hispanohablante. Aunque tiene su sede en Londres, el Hub ha empezado recientemente a ampliar sus operaciones a otras partes del país.<br /><br />
          
          El Hub es miembro del Centro de Investigación de Enfermedades Tropicales Desatendidas de Londres y de la Coalición Global de Chagas, y se centra en tres actividades principales: aumentar la concientización sobre la enfermedad de Chagas entre la comunidad latinoamericana en el Reino Unido y los profesionales de la salud; mejorar los servicios clínicos para las personas con enfermedad de Chagas o en riesgo de padecerla; y llevar a cabo investigaciones sobre la epidemiología y las manifestaciones clínicas de la enfermedad.<br /><br />
          
          Compromiso con la comunidad<br /><br />
          
          Consciente de la necesidad de conocer mejor a su población destinataria, en 2020 el UK Chagas Hub pudo perfeccionar sus métodos para implicar a las comunidades y adaptar sus futuros proyectos para lograr una mayor eficacia. Con este fin, en 2022 el Hub puso en marcha un programa piloto de detección comunitaria en el que colabora con organizaciones latinoamericanas sin ánimo de lucro a nivel local para ofrecer pruebas de Chagas gratuitas en eventos comunitarios. Hasta la fecha, se han detectado aproximadamente 100 nuevos casos de un total de 400 pruebas realizadas.<br /><br />
          
          La prioridad actual del Hub es llegar a grupos migrantes más diversos y realizarles pruebas de detección, así como seguir llevando a cabo investigaciones y estudios para comprender mejor la enfermedad y su impacto en las comunidades latinoamericanas del Reino Unido.
          `,
                    source: `<strong>Fuente de población y población en riesgo</strong>: Censo ONS, 2021.<br />
          <strong>Fuente de datos</strong>: Estudios de investigación patrocinados por LSHTM o iniciativas comunitarias de UK Chagas Hub (datos proporcionados por los investigadores del estudio: N. Elkheir/D. Moore).
          `,
                },
            },
            suica: {
                name: `Cantón de Ginebra/Suiza`,
                description: `La enfermedad de Chagas era una entidad desconocida en Ginebra hasta el año 2000, cuando una serie de casos de insuficiencia cardíaca en personas de origen latinoamericano que se presentaron en Hopitaux Universitaire de Geneve (HUG) llevaron a los médicos a investigar más a fondo. Al sospechar una causa subyacente común para estos casos, se consultaron centros de excelencia en enfermedades tropicales y pruebas adicionales identificaron a la enfermedad de Chagas como la causa.`,
                questions: {
                    moreInfo: `Una respuesta rápida a un escenario alarmante<br /><br />

          Ginebra cuenta con un número considerable de personas de origen latinoamericano, especialmente boliviano. Las investigaciones epidemiológicas llevadas a cabo en el país revelaron que una proporción significativa de mujeres latinoamericanas embarazadas eran positivas para el Chagas, lo que suponía un grave riesgo de transmisión de la infección de madre a hijo. También se puso de manifiesto que algunos pacientes que dieron positivo para Chagas eran donantes de sangre, en un país en el que el cribado de los bancos de sangre no incluía la prueba de Chagas.<br /><br />
          
          Ante esta alarmante realidad y la probabilidad de identificar a más personas que vivían con Chagas, se formó un grupo informal de profesionales sanitarios de distintas disciplinas que empezó a visitar activamente las comunidades afectadas. Informando y proporcionando formación a los médicos de familia, así como a los cardiólogos y obstetras que atienden a la diáspora latinoamericana en Ginebra, el grupo pudo difundir información a las poblaciones de riesgo y referirlas a los servicios ofrecidos en el HUG (Hopitaux Universitaire de Geneve).<br /><br />
          
          Muchos de los afectados por la enfermedad son personas migrantes sin documentación ni seguro médico, lo que les dificulta el acceso a la atención médica por miedo a interactuar con los servicios públicos. El equipo del HUG ha trabajado duro para crear un clima de confianza que facilite la aceptación de las personas afectadas y sus familias de los servicios que ofrece este hospital. Las comunidades han movilizado recursos para sensibilizar a la población local y en 2014 se creó un grupo de apoyo a los afectados por la enfermedad de Chagas (AswissChagas) para reforzar esta labor de sensibilización.<br /><br />
          
          Retos y prioridades futuras<br /><br />
          
          El HUG sigue siendo hoy en día el único lugar del cantón de Ginebra que ofrece atención a las personas afectadas por la enfermedad, a pesar de que ninguno de los dos medicamentos contra el Chagas está registrado en el país. En la actualidad, no existe ningún programa oficial para el Chagas, no hay legislación sobre la enfermedad y ésta no es de declaración obligatoria. Aunque en el HUG se realizan pruebas a todas las mujeres embarazadas de origen latinoamericano, no se aplica sistemáticamente en las comunidades afectadas.<br /><br />
          
          En este contexto, en 2023 se formó la Red Suiza de Chagas con el objetivo de eliminar la enfermedad de Chagas como problema de salud pública en el país para 2030, en línea con la Hoja de Ruta para las enfermedades tropicales desatendidas 2021-2030 que se aprobó en la Asamblea Mundial de la Salud en noviembre de 2020. La Red incluye a expertos en el campo de la enfermedad de Chagas y abarca temas relevantes como la detección, el diagnóstico, el tratamiento, la prevención y el control, con la ambición de trabajar en todos los cantones del país.<br /><br />
          
          Una de las prioridades para un mejor control de la enfermedad en el país es establecer un marco legislativo y político para la enfermedad de Chagas, tanto en Ginebra como a nivel nacional en Suiza para mejorar la detección de casos y el manejo clínico. El acceso a los medicamentos es también un verdadero reto debido a la falta de registro. Por último, pero no menos importante, el fortalecimiento de las organizaciones de la sociedad civil para permitir una mayor movilización de las propias comunidades afectadas sin duda ayudará a abordar el estigma relacionado con la enfermedad de Chagas, facilitar un mayor acceso a la atención sanitaria y garantizar que las políticas e intervenciones para mejorar la atención realmente satisfagan las necesidades de las personas afectadas.
          `,
                    source: `<strong>Fuente de la población</strong>: Eurostat 2019. <br />
          <strong>Fuente de datos</strong>: Informe del Programa de la Enfermedad de Chagas, Sistema Nacional de Vigilancia.          
              `,
                },
            },
            menu: {
                population: `Población General`,
                pregnant: `Embarazadas`,
                children: `Jóvenes y Niños`,
                assistance: `Asistencia`,
                actions: `Acciones`,
                more: `Más Información`,
            },
            intro: {
                label: {
                    population: `Población`,
                    prevalence: `Prevalencia Nacional de Chagas`,
                    risk_population: `Población en Riesgo de Chagas`,
                    risk_vectorial: `Población en Riesgo Vectorial`,
                },
                data: {
                    population: {
                        argentina: `45,892,245 - La población en riesgo se distribuye en las 24 provincias del país debido a los flujos migratorios.`,
                        brasil: `203,080,756`,
                        colombia: `51,682,692`,
                        guatemala: `17,110,000`,
                        paraguai: `6,704,000`,
                        reinounido: `59.6 millones (Reino Unido)`,
                        suica: `499,480`,
                    },
                    prevalence: {
                        argentina: ``,
                        brasil: `Aproximadamente de 1.9 a 4.6 millones de personas`,
                        colombia: `2.0% (IC95%: 1.0-4.0)`,
                        guatemala: ``,
                        paraguai: ``,
                        reinounido: ``,
                        suica: ``,
                    },
                    riskPopulation: {
                        argentina: ``,
                        brasil: ``,
                        colombia: `11,983,942`,
                        guatemala: ``,
                        paraguai: ``,
                        reinounido: `283,721 individuos nacidos en 21 países endémicos y que actualmente residen en Inglaterra y Gales.`,
                        suica: ``,
                    },
                    riskVectorial: {
                        argentina: ``,
                        brasil: ``,
                        colombia: `8,831,294`,
                        guatemala: ``,
                        paraguai: ``,
                        reinounido: ``,
                        suica: ``,
                    },
                },
            },
        },
        questions: {
            tested: {
                2021: {
                    argentina: {
                        q: `Personas tamizadas por edad`,
                        l: "0-1 años, 1-15 años, 15-45 años, 45+ años",
                    },
                    brasil: {
                        q: `Personas tamizadas por edad`,
                        l: "0-1 años, 1-15 años, 16-49 años, 50+ años",
                    },
                    colombia: {
                        q: `Personas tamizadas por edad`,
                        l: "< 5 años, 5-14 años, 15-17 años, 18+ años",
                    },
                    guatemala: {
                        q: `Personas tamizadas por edad`,
                        l: "0-1 años, 1-15 años, 15-49 años, 50+ años",
                    },
                    paraguai: {
                        q: `Personas tamizadas por edad`,
                        l: "0-1 años, 1-15 años, 15-49 años, 50+ años",
                    },
                    reinounido: {
                        q: `Personas tamizadas por edad`,
                        l: "0-1 años, 1-15 años, 15-49 años, 50+ años",
                    },
                    suica: {
                        q: `Personas tamizadas por edad`,
                        l: "0-1 años, 1-15 años, 15-49 años, 50+ años",
                    },
                },
                2022: {
                    argentina: {
                        q: `Personas tamizadas por edad`,
                        l: "0-1 años, 1-15 años, 15-45 años, 45+ años, No especificado",
                    },
                    brasil: {
                        q: `Personas tamizadas por edad`,
                        l: "0-1 años, 1-15 años, 16-49 años, 50+ años",
                    },
                    colombia: {
                        q: `Personas tamizadas por edad`,
                        l: "< 5 años, 5-14 años, 15-17 años, 18+ años",
                    },
                    guatemala: {
                        q: `Personas tamizadas por edad`,
                        l: "0-1 años, 1-15 años, 15-49 años, 50+ años",
                    },
                    paraguai: {
                        q: `Personas tamizadas por edad`,
                        l: "0-1 años, 1-15 años, 15-49 años, 50+ años",
                    },
                    reinounido: {
                        q: `Personas tamizadas por edad`,
                        l: "0-1 años, 1-15 años, 15-49 años, 50+ años",
                    },
                    suica: {
                        q: `Personas tamizadas por edad`,
                        l: "0-1 años, 1-15 años, 15-49 años, 50+ años",
                    },
                },
                2023: {
                    argentina: {
                        q: `Personas tamizadas por edad`,
                        l: "0-1 años, 1-15 años, 15-45 años, 45+ años, No Especificado",
                    },
                    brasil: {
                        q: `Personas tamizadas por edad`,
                        l: "0-1 años, 1-15 años, 16-49 años, 50+ años",
                    },
                    colombia: {
                        q: `Personas tamizadas por edad`,
                        l: "< 5 años, 5-14 años, 15-17 años, 18+ años",
                    },
                    guatemala: {
                        q: `Personas tamizadas por edad`,
                        l: "0-1 años, 1-15 años, 15-49 años, 50+ años, No Clasificado",
                    },
                    paraguai: {
                        q: `Personas tamizadas por edad`,
                        l: "0-1 años, 1-15 años, 15-49 años, 50+ años",
                    },
                    reinounido: {
                        q: `Personas tamizadas por edad`,
                        l: "0-1 años, 1-15 años, 15-49 años, 50+ años",
                    },
                    suica: {
                        q: `Personas tamizadas por edad`,
                        l: "0-1 años, 1-15 años, 15-49 años, 50+ años",
                    },
                },
            },
            genres: {
                2021: {
                    argentina: {
                        q: `Personas tamizadas por género`,
                        l: "Mujeres, Hombres, Otros",
                    },
                    brasil: {
                        q: `Personas tamizadas por género`,
                        l: "Mujeres, Hombres, Otros",
                    },
                    colombia: {
                        q: `Personas tamizadas por género`,
                        l: "Mujeres, Hombres, Otros",
                    },
                    guatemala: {
                        q: `Personas tamizadas por género`,
                        l: "Mujeres, Hombres, Otros",
                    },
                    paraguai: {
                        q: `Personas tamizadas por género`,
                        l: "Mujeres, Hombres, Otros",
                    },
                    reinounido: {
                        q: `Personas tamizadas por género`,
                        l: "Mujeres, Hombres, Otros",
                    },
                    suica: {
                        q: `Personas tamizadas por género`,
                        l: "Mujeres, Hombres, Otros",
                    },
                },
                2022: {
                    argentina: {
                        q: `Personas tamizadas por género`,
                        l: "Mujeres, Hombres, Otros",
                    },
                    brasil: {
                        q: `Personas tamizadas por género`,
                        l: "Mujeres, Hombres, Otros",
                    },
                    colombia: {
                        q: `Personas tamizadas por género`,
                        l: "Mujeres, Hombres, Otros",
                    },
                    guatemala: {
                        q: `Personas tamizadas por género`,
                        l: "Mujeres, Hombres, Otros",
                    },
                    paraguai: {
                        q: `Personas tamizadas por género`,
                        l: "Mujeres, Hombres, Otros",
                    },
                    reinounido: {
                        q: `Personas tamizadas por género`,
                        l: "Mujeres, Hombres, Otros",
                    },
                    suica: {
                        q: `Personas tamizadas por género`,
                        l: "Mujeres, Hombres, Otros",
                    },
                },
                2023: {
                    argentina: {
                        q: `Personas tamizadas por género`,
                        l: "Mujeres, Hombres, Otros",
                    },
                    brasil: {
                        q: `Personas tamizadas por género`,
                        l: "Mujeres, Hombres, Otros",
                    },
                    colombia: {
                        q: `Personas tamizadas por género`,
                        l: "Mujeres, Hombres, Otros",
                    },
                    guatemala: {
                        q: `Personas tamizadas por género`,
                        l: "Mujeres, Hombres, Otros",
                    },
                    paraguai: {
                        q: `Personas tamizadas por género`,
                        l: "Mujeres, Hombres, Otros",
                    },
                    reinounido: {
                        q: `Personas tamizadas por género`,
                        l: "Mujeres, Hombres, Otros",
                    },
                    suica: {
                        q: `Personas tamizadas por género`,
                        l: "Mujeres, Hombres, Otros",
                    },
                },
            },
            1: {
                2021: {
                    argentina: `<strong>[x] de cada [y]</strong> personas tamizadas recibieron un diagnóstico <strong>positivo</strong>.`,
                    brasil: `<strong>[x]</strong> personas tamizadas recibieron un diagnóstico <strong>positivo</strong>.`,
                    colombia: `<strong>[x] de cada [y]</strong> personas tamizadas recibieron un diagnóstico <strong>positivo</strong>.`,
                    guatemala: `Aproximadamente <strong>[x] de cada [y]</strong> personas tamizadas recibieron un diagnóstico <strong>positivo</strong>.`,
                    paraguai: `<strong>[x] de cada [y]</strong> personas tamizadas recibieron un diagnóstico <strong>positivo</strong>.`,
                    reinounido: `<strong>[x] de cada [y]</strong> personas tamizadas recibieron un diagnóstico <strong>positivo</strong>.`,
                    suica: `<strong>[x] de cada [y]</strong> personas tamizadas recibieron un diagnóstico <strong>positivo</strong>.`,
                },
                2022: {
                    argentina: `<strong>[x] de cada [y]</strong> personas tamizadas recibieron un diagnóstico <strong>positivo</strong>.`,
                    brasil: `<strong>[x]</strong> personas fueron tamizadas y de ellas, <strong>[y]</strong> recibieron un diagnóstico <strong>positivo</strong> ([z]).`,
                    colombia: `<strong>[x] de [y]</strong> personas tamizadas recibieron un diagnóstico <strong>positivo</strong>.`,
                    guatemala: `Aproximadamente <strong>[x] de cada [y]</strong> personas tamizadas recibieron un diagnóstico <strong>positivo</strong>.`,
                    paraguai: `<strong>[x] de cada [y]</strong> personas tamizadas recibieron un diagnóstico <strong>positivo</strong>.`,
                    reinounido: `<strong>[x] de cada [y]</strong> personas tamizadas recibieron un diagnóstico <strong>positivo</strong>.`,
                    suica: `Aproximadamente <strong>[x] de cada [y]</strong> personas tamizadas recibieron un diagnóstico <strong>positivo</strong>.`,
                },
                2023: {
                    argentina: `<strong>[x] de cada [y]</strong> personas tamizadas recibieron un diagnóstico <strong>positivo</strong>.`,
                    brasil: `<strong>[x]</strong> personas fueron tamizadas y de ellas, <strong>[y]</strong> recibieron un diagnóstico <strong>positivo</strong> ([z]).`,
                    colombia: `<strong>[x] de cada [y]</strong> personas tamizadas recibieron un diagnóstico <strong>positivo</strong>.`,
                    guatemala: `<strong>[x] de cada [y]</strong> personas tamizadas recibieron un diagnóstico <strong>positivo</strong>.`,
                    paraguai: `Aproximadamente <strong>[x] de cada [y]</strong> personas tamizadas recibieron un diagnóstico <strong>positivo</strong>.`,
                    reinounido: `<strong>[x] de cada [y]</strong> personas tamizadas recibieron un diagnóstico <strong>positivo</strong>.`,
                    suica: `<strong>[x] de cada [y]</strong> personas tamizadas recibieron un diagnóstico <strong>positivo</strong>.`,
                },
            },
            2: `<strong>[x]</strong> persona(s) iniciaron el <strong>tratamiento</strong>.`,
            "2t": {
                2023: {
                    argentina: ``,
                    brasil: ``,
                    colombia: ``,
                    guatemala: ``,
                    paraguai: ``,
                    reinounido: ``,
                    suica: ``,
                },
                2022: {
                    argentina: ``,
                    brasil: `24 pacientes presentaron solo Insuficiencia Cardíaca Congestiva (ICC), 81 pacientes presentaron solo arritmia, y 20 pacientes presentaron tanto ICC como arritmia.`,
                    colombia: ``,
                    guatemala: ``,
                    paraguai: ``,
                    reinounido: ``,
                    suica: ``,
                },
                2021: {
                    argentina: ``,
                    brasil: ``,
                    colombia: ``,
                    guatemala: `Guatemala tiene el control sobre la distribución de frascos de los medicamentos Nifurtimox y Benznidazol. En este año de 2021, se distribuyeron 729 frascos de los dos medicamentos, equivalente a aproximadamente 243 pacientes tratados.`,
                    paraguai: ``,
                    reinounido: ``,
                    suica: ``,
                },
            },
            3: {
                2021: {
                    argentina: `De los casos clasificados, <strong>[x] son crónicos</strong>.`,
                    brasil: `De los casos clasificados, <strong>[x] son agudos</strong>.`,
                    colombia: `De los casos clasificados, <strong>[x] son crónicos</strong>.`,
                    guatemala: `De los casos clasificados, <strong>[x] son crónicos</strong>.`,
                    paraguai: `De los casos clasificados, <strong>[x] son crónicos</strong>.`,
                    reinounido: `De los casos clasificados, <strong>[x] son crónicos</strong>.`,
                    suica: `De los casos clasificados, <strong>[x] son crónicos</strong>.`,
                },
                2022: {
                    argentina: `Aproximadamente <strong>[x] de cada [y]</strong> casos fueron considerados crónicos.`,
                    brasil: `De los casos clasificados, <strong>[x] son agudos</strong>.`,
                    colombia: `De los casos clasificados, <strong>[x] son crónicos</strong>.`,
                    guatemala: `De los casos clasificados, <strong>[x] son crónicos</strong>.`,
                    paraguai: `De los casos clasificados, <strong>[x] son crónicos</strong>.`,
                    reinounido: `<strong>[x]</strong> de los casos diagnosticados son <strong>crónicos</strong>.`,
                    suica: `<strong>[x]</strong> de los casos diagnosticados son <strong>crónicos</strong>.`,
                },
                2023: {
                    argentina: `De los casos clasificados, <strong>[x] son crónicos</strong>.`,
                    brasil: `<strong>[x] de cada [y]</strong> fueron considerados <strong>agudos</strong>.`,
                    colombia: `De los casos clasificados, <strong>[x] son crónicos</strong>.`,
                    guatemala: `De los casos clasificados, <strong>[x] son crónicos</strong>.`,
                    paraguai: `De los casos clasificados, <strong>[x] son crónicos</strong>.`,
                    reinounido: `De los casos clasificados, <strong>[x] son crónicos</strong>.`,
                    suica: `De los casos clasificados, <strong>[x] son crónicos</strong>.`,
                },
            },
            "3t": {
                2023: {
                    argentina: ``,
                    brasil: `Actualmente, el país clasifica todos los casos como agudos. Se está implementando la notificación obligatoria de los casos crónicos.`,
                    colombia: ``,
                    guatemala: ``,
                    paraguai: ``,
                    reinounido: ``,
                    suica: ``,
                },
                2022: {
                    argentina: ``,
                    brasil: `Actualmente, el país clasifica todos los casos como agudos. Se está implementando la notificación obligatoria de los casos crónicos.`,
                    colombia: ``,
                    guatemala: ``,
                    paraguai: ``,
                    reinounido: ``,
                    suica: ``,
                },
                2021: {
                    argentina: ``,
                    brasil: `Actualmente, el país clasifica todos los casos como agudos. Se está implementando la notificación obligatoria de los casos crónicos.`,
                    colombia: ``,
                    guatemala: ``,
                    paraguai: ``,
                    reinounido: ``,
                    suica: ``,
                },
            },
            4: `<strong>Formas de transmisión</strong>`,
            5: {
                2023: {
                    argentina: `Aproximadamente <strong>[x] de cada [y]</strong> personas tamizadas estaban <strong>embarazadas</strong>.`,
                    brasil: `Aproximadamente <strong>[x] de cada [y]</strong> personas tamizadas estaban <strong>embarazadas</strong>.`,
                    colombia: `<strong>[x] de cada [y]</strong> personas tamizadas estaban <strong>embarazadas</strong>.`,
                    guatemala: `<strong>[x] de cada [y]</strong> personas tamizadas estaban <strong>embarazadas</strong>.`,
                    paraguai: `Aproximadamente <strong>[x] de cada [y]</strong> personas tamizadas estaban <strong>embarazadas</strong>.`,
                    reinounido: `<strong>[x] de cada [y]</strong> personas tamizadas estaban <strong>embarazadas</strong>.`,
                    suica: `<strong>[x] de cada [y]</strong> personas tamizadas estaban <strong>embarazadas</strong>.`,
                },
                2022: {
                    argentina: `Aproximadamente <strong>[x] de cada [y]</strong> personas tamizadas estaban <strong>embarazadas</strong>.`,
                    brasil: `Aproximadamente <strong>[x] de cada [y]</strong> personas tamizadas estaban <strong>embarazadas</strong>.`,
                    colombia: `Aproximadamente <strong>[x] de cada [y]</strong> personas tamizadas estaban <strong>embarazadas</strong>.`,
                    guatemala: `<strong>[x] de cada [y]</strong> personas tamizadas estaban <strong>embarazadas</strong>.`,
                    paraguai: `Aproximadamente <strong>[x] de cada [y]</strong> personas tamizadas estaban <strong>embarazadas</strong>.`,
                    reinounido: `De las <strong>267</strong> personas tamizadas, <strong>80</strong> estaban <strong>embarazadas</strong>.`,
                    suica: `<strong>[x] de cada [y]</strong> personas tamizadas estaban <strong>embarazadas</strong>.`,
                },
                2021: {
                    argentina: `Aproximadamente <strong>[x] de cada [y]</strong> personas tamizadas estaban <strong>embarazadas</strong>.`,
                    brasil: `<strong>[x]</strong> de las personas tamizadas estaban <strong>embarazadas</strong>.`,
                    colombia: `<strong>[x] de cada [y]</strong> personas tamizadas estaban <strong>embarazadas</strong>.`,
                    guatemala: `<strong>[x] de cada [y]</strong> personas tamizadas estaban <strong>embarazadas</strong>.`,
                    paraguai: `<strong>[x] de cada [y]</strong> personas tamizadas estaban <strong>embarazadas</strong>.`,
                    reinounido: `<strong>[x] de cada [y]</strong> personas tamizadas estaban <strong>embarazadas</strong>.`,
                    suica: `<strong>[x] de cada [y]</strong> personas tamizadas estaban <strong>embarazadas</strong>.`,
                },
            },
            "5t": {
                argentina: ``,
                brasil: ``,
                colombia: ``,
                guatemala: `Dado subnotificado já que o laboratório descentralizado de Jutiapa não registra mulheres grávidas.`,
                paraguai: ``,
                reinounido: ``,
                suica: ``,
            },
            6: {
                2023: {
                    argentina: `De <strong>[x]</strong> mil mujeres embarazadas tamizadas, aproximadamente <strong>[y]</strong> dieron positivo.`,
                    brasil: `<strong>[x] de cada [y]</strong> mujeres embarazadas dieron positivo.`,
                    colombia: `De <strong>[x]</strong> mujeres embarazadas tamizadas, aproximadamente <strong>[y]</strong> dieron positivo.`,
                    guatemala: `De <strong>[x]</strong> mujeres embarazadas tamizadas, <strong>[y]</strong> dieron positivo.`,
                    paraguai: `<strong>[y]</strong> mujeres embarazadas dieron positivo.`,
                    reinounido: `De <strong>[x]</strong> mujeres embarazadas tamizadas, aproximadamente <strong>[y]</strong> dieron positivo.`,
                    suica: `De <strong>[x]</strong> mujeres embarazadas tamizadas, aproximadamente <strong>[y]</strong> dieron positivo.`,
                },
                2022: {
                    argentina: `De <strong>[x]</strong> mil mujeres embarazadas tamizadas, aproximadamente <strong>[y]</strong> dieron positivo.`,
                    brasil: `Solo <strong>[y]</strong> mujeres embarazadas dieron positivo.`,
                    colombia: `<strong>[y]</strong> de las mujeres embarazadas dieron positivo.`,
                    guatemala: `<strong>1 de cada [y]</strong> mujeres embarazadas dieron positivo.`,
                    paraguai: `<strong>[y]</strong> mujeres embarazadas dieron positivo.`,
                    reinounido: `De <strong>[x]</strong> mujeres embarazadas tamizadas, <strong>0</strong> (ninguna) dio positivo.`,
                    suica: `<strong>[y]</strong> mujer embarazada dio positivo.`,
                },
                2021: {
                    argentina: `<strong>[y]</strong> de las mujeres embarazadas tamizadas dieron positivo.`,
                    brasil: `<strong>[y]</strong> de las mujeres embarazadas tamizadas dieron positivo.`,
                    colombia: `De <strong>[x]</strong> mujeres embarazadas tamizadas, aproximadamente <strong>[y]</strong> dieron positivo.`,
                    guatemala: `<strong>[y]</strong> mujeres embarazadas dieron positivo.`,
                    paraguai: `<strong>[y]</strong> de las mujeres embarazadas tamizadas dieron positivo.`,
                    reinounido: `De <strong>[x]</strong> mujeres embarazadas tamizadas, aproximadamente <strong>[y]</strong> dieron positivo.`,
                    suica: `De <strong>[x]</strong> mujeres embarazadas tamizadas, aproximadamente <strong>[y]</strong> dieron positivo.`,
                },
            },
            "6t": {
                argentina: ``,
                brasil: ``,
                colombia: ``,
                guatemala: `Subnotificado ya que el laboratorio descentralizado en Jutiapa no registra mujeres embarazadas.`,
                paraguai: ``,
                reinounido: ``,
                suica: ``,
            },
            7: {
                2023: {
                    argentina: `<strong>[x]</strong> mujeres embarazadas iniciaron el <strong>tratamiento</strong>.`,
                    brasil: `<strong>[x]</strong> de las mujeres embarazadas diagnosticadas iniciaron <strong>tratamiento</strong>.`,
                    colombia: `<strong>[x]</strong> mujeres embarazadas iniciaron el <strong>tratamiento</strong>.`,
                    guatemala: `<strong>[x]</strong> mujeres embarazadas iniciaron el <strong>tratamiento</strong>.`,
                    paraguai: `<strong>[x]</strong> mujeres embarazadas iniciaron el <strong>tratamiento</strong>.`,
                    reinounido: `<strong>[x]</strong> mujeres embarazadas iniciaron el <strong>tratamiento</strong>.`,
                    suica: `<strong>[x]</strong> mujeres embarazadas iniciaron el <strong>tratamiento</strong>.`,
                },
                2022: {
                    argentina: `<strong>[x]</strong> mujeres embarazadas iniciaron el <strong>tratamiento</strong>.`,
                    brasil: `<strong>[x]</strong> de las mujeres embarazadas diagnosticadas iniciaron <strong>tratamiento</strong>.`,
                    colombia: `<strong>[x]</strong> mujeres embarazadas iniciaron el <strong>tratamiento</strong>.`,
                    guatemala: `<strong>[x]</strong> mujeres embarazadas iniciaron el <strong>tratamiento</strong>.`,
                    paraguai: `<strong>[x]</strong> mujeres embarazadas iniciaron el <strong>tratamiento</strong>.`,
                    reinounido: `<strong>[x]</strong> mujeres embarazadas iniciaron el <strong>tratamiento</strong>.`,
                    suica: `<strong>[x]</strong> mujeres embarazadas iniciaron el <strong>tratamiento</strong>.`,
                },
                2021: {
                    argentina: `<strong>[x]</strong> mujeres embarazadas iniciaron el <strong>tratamiento</strong>.`,
                    brasil: `<strong>[x]</strong> de las mujeres embarazadas diagnosticadas iniciaron <strong>tratamiento</strong>.`,
                    colombia: `<strong>[x]</strong> mujeres embarazadas iniciaron el <strong>tratamiento</strong>.`,
                    guatemala: `<strong>[x]</strong> mujeres embarazadas iniciaron el <strong>tratamiento</strong>.`,
                    paraguai: `<strong>[x]</strong> mujeres embarazadas iniciaron el <strong>tratamiento</strong>.`,
                    reinounido: `<strong>[x]</strong> mujeres embarazadas iniciaron el <strong>tratamiento</strong>.`,
                    suica: `<strong>[x]</strong> mujeres embarazadas iniciaron el <strong>tratamiento</strong>.`,
                },
            },
            "7t": {
                2021: {
                    argentina: `Este año, Argentina solo registró el inicio del tratamiento para mujeres embarazadas.`,
                    brasil: ``,
                    colombia: ``,
                    guatemala: `Guatemala tiene control sobre la distribución de frascos de los medicamentos Nifurtimox y Benznidazol. En este año 2021, se distribuyeron 729 frascos de los dos medicamentos, equivalentes a aproximadamente 243 pacientes tratados.`,
                    paraguai: `55 eran mujeres y 45 hombres.`,
                    reinounido: ``,
                    suica: ``,
                },
                2022: {
                    argentina: ``,
                    brasil: ``,
                    colombia: ``,
                    guatemala: `Algunas de las personas que comenzaron el tratamiento fueron diagnosticadas en años anteriores.`,
                    paraguai: `No hay clasificación por edad y sexo, así como si está embarazada.`,
                    reinounido: `Este país no clasifica entre las personas que iniciaron tratamiento, sexo y edad.`,
                    suica: `La persona que comenzó el tratamiento tenía entre 15 y 49 años, no estaba embarazada.`,
                },
                2023: {
                    argentina: ``,
                    brasil: ``,
                    colombia: ``,
                    guatemala: ``,
                    paraguai: `70 eran mujeres y 49 hombres.`,
                    reinounido: ``,
                    suica: ``,
                },
            },
            8: {
                2023: {
                    argentina: `Aproximadamente <strong>[x] de cada [y]</strong> personas tamizadas y clasificadas por edad eran <strong>menores de [z] años</strong>.`,
                    brasil: `Aproximadamente <strong>[x] de cada [y]</strong> personas tamizadas eran <strong>menores de [z] años</strong>.`,
                    colombia: `<strong>[x] de cada [y]</strong> personas tamizadas eran <strong>menores de [z] años</strong>.`,
                    guatemala: `<strong>[x] de cada [y]</strong> personas tamizadas eran <strong>menores de [z] años</strong>.`,
                    paraguai: `Aproximadamente <strong>[x] de cada [y]</strong> personas tamizadas eran <strong>menores de [z] años</strong>.`,
                    reinounido: `<strong>[x] de cada [y]</strong> personas tamizadas eran <strong>menores de [z] años</strong>.`,
                    suica: `<strong>[x] de cada [y]</strong> personas tamizadas eran <strong>menores de [z] años</strong>.`,
                },
                2022: {
                    argentina: `Aproximadamente <strong>[x] de cada [y]</strong> personas tamizadas y clasificadas por edad eran <strong>menores de [z] años</strong>.`,
                    brasil: `Aproximadamente <strong>[x] de cada [y]</strong> personas tamizadas eran <strong>menores de [z] años</strong>.`,
                    colombia: `<strong>[x] de cada [y]</strong> personas tamizadas eran <strong>menores de [z] años</strong>.`,
                    guatemala: `<strong>[x] de cada [y]</strong> personas tamizadas eran <strong>menores de [z] años</strong>.`,
                    paraguai: `<strong>[x] de cada [y]</strong> personas tamizadas eran <strong>menores de [z] años</strong>.`,
                    reinounido: `Aproximadamente <strong>[x] de cada [y]</strong> personas tamizadas eran <strong>menores de [z] años</strong>.`,
                    suica: `Aproximadamente <strong>[x] de cada [y]</strong> personas tamizadas eran <strong>menores de [z] años</strong>.`,
                },
                2021: {
                    argentina: `<strong>[x] de cada [y]</strong> personas tamizadas y clasificadas por edad eran <strong>menores de [z] años</strong>.`,
                    brasil: `<strong>1 de cada 10</strong> personas tamizadas eran <strong>menores de [z] años</strong>.`,
                    colombia: `<strong>[x] de cada [y]</strong> personas tamizadas eran <strong>menores de [z] años</strong>.`,
                    guatemala: `<strong>[x] de cada [y]</strong> personas tamizadas eran <strong>menores de [z] años</strong>.`,
                    paraguai: `<strong>[x]</strong> de las personas tamizadas eran <strong>menores de [z] años</strong>.`,
                    reinounido: `<strong>[x] de cada [y]</strong> personas tamizadas eran <strong>menores de [z] años</strong>.`,
                    suica: `<strong>[x] de cada [y]</strong> personas tamizadas eran <strong>menores de [z] años</strong>.`,
                },
            },
            9: {
                2021: {
                    argentina: `<strong>[x]</strong> de los jóvenes menores de 15 años que fueron tamizados y clasificados según la edad recibieron un <strong>diagnóstico positivo</strong>.`,
                    brasil: `Aproximadamente <strong>2 de cada 100</strong> jóvenes menores de 15 años tamizados recibieron un <strong>diagnóstico positivo</strong>.`,
                    colombia: `<strong>[x]</strong> de los jóvenes menores de [z] años que fueron tamizados y clasificados según la edad recibieron un <strong>diagnóstico positivo</strong>.`,
                    guatemala: `<strong>[x]</strong> de los jóvenes menores de 15 años que fueron tamizados recibieron un <strong>diagnóstico positivo</strong>.`,
                    paraguai: `<strong>[x]</strong> jóvenes menores de 15 años tamizados recibieron un <strong>diagnóstico positivo</strong>.`,
                    reinounido: `<strong>[x]</strong> de los jóvenes menores de 15 años que fueron tamizados y clasificados según la edad recibieron un <strong>diagnóstico positivo</strong>.`,
                    suica: `<strong>[x]</strong> de los jóvenes menores de 15 años que fueron tamizados y clasificados según la edad recibieron un <strong>diagnóstico positivo</strong>.`,
                },
                2022: {
                    argentina: `<strong>[x]</strong> de los jóvenes menores de 15 años que fueron tamizados y clasificados según la edad recibieron un <strong>diagnóstico positivo</strong>.`,
                    brasil: `Aproximadamente <strong>2 de cada [x]</strong> jóvenes menores de 15 años tamizados recibieron un <strong>diagnóstico positivo</strong>.`,
                    colombia: `<strong>[x]</strong> de los jóvenes menores de [z] años recibieron un <strong>diagnóstico positivo</strong>.`,
                    guatemala: `Aproximadamente <strong>7 de cada [x]</strong> jóvenes menores de 15 años tamizados recibieron un <strong>diagnóstico positivo</strong>.`,
                    paraguai: `<strong>[x]</strong> jóvenes menores de 15 años recibieron un <strong>diagnóstico positivo</strong>.`,
                    reinounido: `<strong>Ninguno</strong> de los jóvenes menores de 15 años que fueron tamizados y clasificados según la edad recibieron un <strong>diagnóstico positivo</strong>.`,
                    suica: `<strong>[x]</strong> de los jóvenes menores de 15 años que fueron tamizados recibieron un <strong>diagnóstico positivo</strong>.`,
                },
                2023: {
                    argentina: `<strong>[x]</strong> de los jóvenes menores de 15 años que fueron tamizados y clasificados según la edad recibieron un <strong>diagnóstico positivo</strong>.`,
                    brasil: `Aproximadamente <strong>1 de cada [x]</strong> jóvenes menores de 15 años tamizados recibieron un <strong>diagnóstico positivo</strong>.`,
                    colombia: `<strong>[x]</strong> de los jóvenes menores de [z] años que fueron tamizados y clasificados según la edad recibieron un <strong>diagnóstico positivo</strong>.`,
                    guatemala: `<strong>[x]</strong> de los jóvenes menores de 15 años que fueron tamizados y clasificados según la edad recibieron un <strong>diagnóstico positivo</strong>.`,
                    paraguai: `<strong>4 de cada [x]</strong> jóvenes menores de 15 años tamizados recibieron un <strong>diagnóstico positivo</strong>.`,
                    reinounido: `<strong>[x]</strong> de los jóvenes menores de 15 años que fueron tamizados y clasificados según la edad recibieron un <strong>diagnóstico positivo</strong>.`,
                    suica: `<strong>[x]</strong> de los jóvenes menores de 15 años que fueron tamizados y clasificados según la edad recibieron un <strong>diagnóstico positivo</strong>.`,
                },
            },
            10: {
                2023: {
                    argentina: `<strong>[x] </strong> jóvenes menores de [z] años iniciaron el <strong>tratamiento</strong>.`,
                    brasil: `<strong>[x] </strong> jóvenes menores de [z] años iniciaron el <strong>tratamiento</strong>.`,
                    colombia: `<strong>[x] </strong> jóvenes menores de [z] años iniciaron el <strong>tratamiento</strong>.`,
                    guatemala: `<strong>[x] </strong> jóvenes menores de [z] años iniciaron el <strong>tratamiento</strong>.`,
                    paraguai: `<strong>[x] </strong> jóvenes menores de [z] años iniciaron el <strong>tratamiento</strong>.`,
                    reinounido: `<strong>[x] </strong> jóvenes menores de [z] años iniciaron el <strong>tratamiento</strong>.`,
                    suica: `<strong>[x] </strong> jóvenes menores de [z] años iniciaron el <strong>tratamiento</strong>.`,
                },
                2022: {
                    argentina: `<strong>[x] </strong> jóvenes menores de [z] años iniciaron el <strong>tratamiento</strong>.`,
                    brasil: `<strong>94.4% ([x]) </strong> de los jóvenes diagnosticados iniciaron el <strong>tratamiento</strong>.`,
                    colombia: `<strong>62.5% ([x]) </strong> de los jóvenes menores de [z] años diagnosticados iniciaron el <strong>tratamiento</strong>.`,
                    guatemala: `<strong>[x] </strong> jóvenes menores de [z] años iniciaron el <strong>tratamiento</strong>.`,
                    paraguai: `<strong>[x] </strong> jóvenes menores de [z] años iniciaron el <strong>tratamiento</strong>.`,
                    reinounido: `<strong>[x] </strong> jóvenes menores de [z] años iniciaron el <strong>tratamiento</strong>.`,
                    suica: `<strong>[x] </strong> jóvenes menores de [z] años iniciaron el <strong>tratamiento</strong>.`,
                },
                2021: {
                    argentina: `<strong>[x] </strong> jóvenes menores de [z] años iniciaron el <strong>tratamiento</strong>.`,
                    brasil: `<strong>[x] </strong> jóvenes menores de [z] años iniciaron el <strong>tratamiento</strong>.`,
                    colombia: `<strong>[x] </strong> jóvenes menores de [z] años iniciaron el <strong>tratamiento</strong>.`,
                    guatemala: `<strong>[x] </strong> jóvenes menores de [z] años iniciaron el <strong>tratamiento</strong>.`,
                    paraguai: `<strong>[x] </strong> jóvenes menores de [z] años iniciaron el <strong>tratamiento</strong>.`,
                    reinounido: `<strong>[x] </strong> jóvenes menores de [z] años iniciaron el <strong>tratamiento</strong>.`,
                    suica: `<strong>[x] </strong> jóvenes menores de [z] años iniciaron el <strong>tratamiento</strong>.`,
                },
            },
            11: `¿Existe una <strong>planificación</strong> y previsión sistemáticas de la demanda para la adquisición de medicamentos y pruebas diagnósticas?`,
            12: `¿Tanto el nifurtimox como el benznidazol tienen <strong>registro válido</strong> en el país?`,
            "12t": {
                argentina: ``,
                brasil: `El Nifurtimox aún no está registrado.`,
                colombia: `El Benznidazol aún no está registrado.`,
                guatemala: ``,
                paraguai: ``,
                reinounido: `Ni el Benznidazol ni el Nifurtimox están registrados en el país.`,
                suica: ``,
            },
            13: `¿Tanto el nifurtimox como el benznidazol están <strong>disponibles</strong> en el país?`,
            "13t": {
                argentina: ``,
                brasil: ``,
                colombia: ``,
                guatemala: ``,
                paraguai: ``,
                reinounido: `El Benznidazol está disponible a través de la OMS, en Ginebra. Sin embargo, ha habido problemas recientes de suministro debido al rechazo de importación del medicamento que proviene de Argentina (ELEA/Mundo Sano) por parte de la Agencia Reguladora de Medicamentos y Productos Sanitarios.`,
                suica: `Ninguno de los medicamentos está registrado, pero pueden solicitarse en caso de necesidad. Solo el Hospital Universitario de Ginebra lo hace regularmente.`,
            },
            14: `¿El país utiliza <strong>pruebas diagnósticas rápidas</strong>?`,
            "14t": {
                argentina: ``,
                brasil: `Las pruebas diagnósticas rápidas están en proceso de incorporación por el proyecto IntegraChagas. El SUS aún recomienda la serología para el diagnóstico de la enfermedad de Chagas en el país. Pruebas diagnósticas con registro en el país: Bio-manguinhos, Alere Chagas®, AbCombo® y WL Check Chagas.`,
                colombia: ``,
                guatemala: ``,
                paraguai: `Pruebas diagnósticas con registro en el país: Chagas Stat-Pak assay (Chemio - USA), WL Check Chagas (Wiener Lab - Argentina), Nova Test (China), Artron (Canadá), All test Biotech (China). Los proveedores tienen un permiso de exportación/importación válido por 1 año, renovable. Las pruebas rápidas están validadas e incorporadas en el algoritmo diagnóstico del país.`,
                reinounido: `Pruebas diagnósticas con registro en el país: Stat Pak (ChemBio) e Inbios CDP.`,
                suica: `Prueba diagnóstica con registro en el país: Stat Pak (ChemBio).`,
            },
            15: `¿Se realiza una evaluación cardiológica y de otras posibles complicaciones en la <strong>atención primaria de salud</strong>?`,
            "15t": {
                argentina: ``,
                brasil: `La evaluación cardiológica es recomendada por el PCDT, pero su ejecución por la APS es evaluada a través de la Secretaría de Atención Primaria en Salud.`,
                colombia: ``,
                guatemala: `En el primer nivel de atención no se realiza evaluación cardiológica ni de otras posibles complicaciones.`,
                paraguai: `Solo se realiza un electrocardiograma en la atención primaria de salud.`,
                reinounido: `Evaluación cardiológica realizada en la atención secundaria.`,
                suica: ``,
            },
            16: `¿Existe un <strong>Programa Nacional</strong> o Subprograma activo para la enfermedad de Chagas?`,
            17: `¿Existe un <strong>presupuesto</strong> del Ministerio de Salud dedicado al Programa de Chagas?`,
            "17t": {
                argentina: ``,
                brasil: ``,
                colombia: ``,
                guatemala: `No hay un presupuesto dedicado para la enfermedad de Chagas. El presupuesto es para todo el Programa de Vectores, del cual Chagas forma parte junto con otras enfermedades.`,
                paraguai: ``,
                reinounido: ``,
                suica: ``,
            },
            18: `¿Existe una <strong>guía o protocolo nacional</strong> publicado para el diagnóstico y tratamiento?`,
            "18t": {
                argentina: `Última actualización en 2018. <a target="_blank" href="https://bancos.salud.gob.ar/sites/default/files/2020-01/chagas-atencion-paciente-infectado-2018.pdf">Enlace</a>`,
                brasil: `Última actualización en 2018. <a target="_blank" href="https://www.gov.br/conitec/pt-br/midias/protocolos/resumidos/PCDTResumidoDoenadeChagas.pdf">Enlace</a>`,
                colombia: `El país adoptó la Guía para el diagnóstico y tratamiento de la enfermedad de Chagas, de la Organización Panamericana de la Salud. Washington, DC: OPS; 2018.`,
                guatemala: `En 2021 se publicó el Manual Operativo para la Vigilancia y Control Entomológico de la Enfermedad de Chagas. En 2023 se publicaron las Normas de Atención Integral en Salud para el Primer y Segundo Nivel.`,
                paraguai: `El país cuenta con las primeras guías de manejo clínico desde 2021.`,
                reinounido: `Guía de salud para migrantes en el Reino Unido, publicada en 2014, actualizada en 2021.`,
                suica: ``,
            },
            19: `¿El país cuenta con <strong>espacios formales para la participación de la sociedad</strong> en la formulación de políticas para el control y eliminación de la enfermedad?`,
            20: `¿las personas afectadas o personas afectadas están organizados en <strong>asociaciones</strong>?`,
            "20t": {
                argentina: ``,
                brasil: ``,
                colombia: ``,
                guatemala: ``,
                paraguai: ``,
                reinounido: ``,
                suica: `La SwissChagas se creó en 2015. Contacto: <a href="mailto:aswisschagas@hotmail.com">aswisschagas@hotmail.com</a>`,
            },
            21: `¿Se <strong>celebra</strong> el Día de la Enfermedad de Chagas?`,
            "21t": {
                argentina: `Se celebran dos fechas: Dia Mundial el 14 de abril, Día Nacional en el último viernes del mes de agosto en cada año.`,
                brasil: `En este país, la celebración tiene lugar cada 14 de abril.`,
                colombia: `Se celebran dos fechas: Día Mundial el 14 de abril, Día Nacional el último día de agosto de cada año.`,
                guatemala: `En este país, el Día Mundial de la Enfermedad de Chagas se celebra oficialmente el 14 de abril y el 9 de julio.`,
                paraguai: `En este país, la celebración tiene lugar cada 14 de abril.`,
                reinounido: `En este país, la celebración tiene lugar cada 14 de abril.`,
                suica: ``,
            },
            22: `¿El país implementa <strong>ETMI Plus</strong>?`,
            "22t": {
                general: `ETMI Plus es el marco para la eliminación de la transmisión materno-infantil del VIH, sífilis, hepatitis B y la enfermedad de Chagas.`,
                argentina: `El país forma parte de la iniciativa.`,
                brasil: ``,
                colombia: ``,
                guatemala: `El país tiene la intención de incluir Chagas en la estrategia, sin embargo, aún necesitan validar y poner a disposición las pruebas rápidas a nivel nacional.`,
                paraguai: ``,
                reinounido: ``,
                suica: ``,
            },
            23: `¿Se implementa el monitoreo de pacientes positivos identificados a través de <strong>donaciones de sangre</strong> a nivel nacional?`,
            "23t": {
                argentina: ``,
                brasil: ``,
                colombia: `Colombia ha establecido metas en el plan decenal de salud pública 2022 - 2031. Entre las metas está el seguimiento de personas que resultan positivas también para los bancos de sangre.`,
                guatemala: ``,
                paraguai: `Actualmente, el país está en conversaciones internas y alineamientos para iniciar el monitoreo.`,
                reinounido: ``,
                suica: ``,
            },
            24: `¿Está interrumpida y certificada la <strong>transmisión vectorial intradomiciliaria</strong> en todas las áreas endémicas?`,
            "24t": {
                argentina: ``,
                brasil: `Brasil está certificado para la eliminación de T. infestans; sin embargo, otras especies de triatominos aún colonizan el ambiente intradomiciliario.`,
                colombia: `En el año 2022, 66 municipios formaron parte del plan de interrupción y otros 33 municipios están por ser incorporados.`,
                guatemala: `En 2019, Guatemala fue certificada por la eliminación de Rhodnius prolixus, uno de los vectores de la enfermedad de Chagas en el país.`,
                paraguai: ``,
                reinounido: ``,
                suica: ``,
            },
            25: `¿El país <strong>registra casos agudos</strong> a nivel nacional?`,
            "25t": {
                argentina: ``,
                brasil: ``,
                colombia: ``,
                guatemala: `El país reconoce la necesidad de reforzar las actividades de búsqueda activa y vigilancia epidemiológica para aumentar el número de casos agudos detectados.`,
                paraguai: ``,
                reinounido: ``,
                suica: ``,
            },
            26: `¿El país <strong>registra casos crónicos</strong> a nivel nacional?`,
            "26t": {
                argentina: ``,
                brasil: `En proceso de implementación`,
                colombia: `Los casos crónicos se notifican parcialmente al sistema nacional de vigilancia epidemiológica. Desde 2018, solo se han registrado casos en menores de 18 años, indígenas, mujeres embarazadas y mujeres en edad fértil.`,
                guatemala: ``,
                paraguai: ``,
                reinounido: ``,
                suica: ``,
            },
            27: `Más Información`,
        },
        data: {
            2021: {
                percentInTreatment: {
                    argentina: `Este año, Argentina solo registró el inicio del tratamiento para mujeres embarazadas.`,
                    brasil: ``,
                    colombia: ``,
                    guatemala: ``,
                    paraguai: `55 eran mujeres y 45 eran hombres.`,
                    reinounido: ``,
                    suica: ``,
                },
            },
            2022: {
                percentInTreatment: {
                    argentina: ``,
                    brasil: ``,
                    colombia: ``,
                    guatemala: `Algunas de las personas que iniciaron el tratamiento fueron diagnosticadas en años anteriores.`,
                    paraguai: `No hay clasificación por edad y sexo, así como si está embarazada.`,
                    reinounido: `Este país no clasifica entre las personas que iniciaron el tratamiento, sexo y edad.`,
                    suica: `La persona que inició el tratamiento tenía entre 15 y 49 años, no estaba embarazada.`,
                },
            },
            2023: {
                percentInTreatment: {
                    argentina: ``,
                    brasil: `Algunas de las personas que iniciaron el tratamiento fueron diagnosticadas en años anteriores.`,
                    colombia: ``,
                    guatemala: ``,
                    paraguai: `70 eran mujeres y 49 eran hombres.`,
                    reinounido: ``,
                    suica: ``,
                },
            },
        },
        tooltips: {
            1: ``,
            "1a": {
                2023: {
                    argentina: `mil`,
                    brasil: `mil`,
                    colombia: `mil`,
                    guatemala: `mil`,
                    paraguai: `mil`,
                    reinounido: `mil`,
                    suica: `mil`,
                },
                2022: {
                    argentina: `mil`,
                    brasil: `mil`,
                    colombia: `millón`,
                    guatemala: `mil`,
                    paraguai: `mil`,
                    reinounido: `mil`,
                    suica: `mil`,
                },
                2021: {
                    argentina: `mil`,
                    brasil: `mil`,
                    colombia: `mil`,
                    guatemala: `mil`,
                    paraguai: `mil`,
                    reinounido: `mil`,
                    suica: `mil`,
                },
            },
            "1b": `personas tamizadas`,
            "1c": `mil`,
            "1d": `diagnósticos positivos`,
            "1e": `Mujeres`,
            "1f": `Hombres`,
            "1g": `Otros`,
            2: `de los diagnosticados comenzaron el tratamiento`,
            3: ``,
            "3a": `agudo`,
            "3b": `crónico`,
            4: ``,
            "4a": `Transmisión Oral`,
            "4b": `Transmisión Vectorial`,
            "4c": `Transmisión Vertical`,
            "4d": `No Clasificado`,
            5: ``,
            "5a": `mil`,
            "5b": `personas tamizadas`,
            "5c": `mil`,
            "5d": `mujeres embarazadas`,
            "5e": `Mujeres`,
            "5f": `Hombres`,
            "5g": `Otros`,
            6: ``,
            "6a": `mil`,
            "6b": `mujeres embarazadas`,
            "6c": `mil`,
            "6d": `diagnósticos positivos`,
            "6e": `Mujeres`,
            "6f": `Hombres`,
            "6g": `Otros`,
            7: {
                2023: {
                    argentina: `de las mujeres embarazadas diagnosticadas comenzaron el tratamiento`,
                    brasil: `mujeres embarazadas diagnosticadas comenzaron el tratamiento`,
                    colombia: `de las mujeres embarazadas diagnosticadas comenzaron el tratamiento`,
                    guatemala: `de las mujeres embarazadas diagnosticadas comenzaron el tratamiento`,
                    paraguai: `de las mujeres embarazadas diagnosticadas comenzaron el tratamiento`,
                    reinounido: `de las mujeres embarazadas diagnosticadas comenzaron el tratamiento`,
                    suica: `de las mujeres embarazadas diagnosticadas comenzaron el tratamiento`,
                },
                2022: {
                    argentina: `de las mujeres embarazadas diagnosticadas comenzaron el tratamiento`,
                    brasil: `de las mujeres embarazadas diagnosticadas comenzaron el tratamiento`,
                    colombia: `de las mujeres embarazadas diagnosticadas comenzaron el tratamiento`,
                    guatemala: `de las mujeres embarazadas diagnosticadas comenzaron el tratamiento`,
                    paraguai: `de las mujeres embarazadas diagnosticadas comenzaron el tratamiento`,
                    reinounido: `de las mujeres embarazadas diagnosticadas comenzaron el tratamiento`,
                    suica: `de las mujeres embarazadas diagnosticadas comenzaron el tratamiento`,
                },
                2021: {
                    argentina: `de las mujeres embarazadas diagnosticadas comenzaron el tratamiento`,
                    brasil: `de las mujeres embarazadas diagnosticadas comenzaron el tratamiento`,
                    colombia: `de las mujeres embarazadas diagnosticadas comenzaron el tratamiento`,
                    guatemala: `de las mujeres embarazadas diagnosticadas comenzaron el tratamiento`,
                    paraguai: `de las mujeres embarazadas diagnosticadas comenzaron el tratamiento`,
                    reinounido: `de las mujeres embarazadas diagnosticadas comenzaron el tratamiento`,
                    suica: `de las mujeres embarazadas diagnosticadas comenzaron el tratamiento`,
                },
            },
            8: ``,
            "8a": `mil`,
            "8b": `personas tamizadas`,
            "8c": `mil`,
            "8d": `menores de [z] años tamizados`,
            "8e": `Jóvenes`,
            "8f": `Niños`,
            "8g": `Otros`,
            9: ``,
            "9a": `mil`,
            "9b": `menores de [z] años tamizados`,
            "9c": `mil`,
            "9d": `diagnósticos positivos`,
            "9e": `Jóvenes`,
            "9f": `Niños`,
            "9g": `Otros`,
            10: `de los jóvenes diagnosticados comenzaron el <strong>tratamiento</strong>`,
            11: ``,
            12: ``,
            13: ``,
            14: ``,
            15: ``,
            16: ``,
            17: ``,
            18: ``,
            19: ``,
            20: ``,
            21: ``,
            22: ``,
            23: ``,
            24: ``,
            25: ``,
            26: ``,
        },
        general: {
            readMore: `Leer más`,
        },
        footer: {
            title: `¿Qué te parece el Observatorio?`,
            helpText: `¡Ayúdanos a promover esta iniciativa y atraer a más actores interesados! ¡Compártelo!`,
            contact: `Ponte en contacto con nosotros`,
            text: `Realizado por`,
        },
    },
};

export default data;
